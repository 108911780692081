import Axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { Bar } from 'react-chartjs-2'
import { Tab, Tabs, Spinner } from 'react-bootstrap'

import NotificationContext from '../../contexts/notification'
import { GetUsageReportVerificationIncomeChart as GetChart } from '../../utils/api'
import { chartColors } from '../../utils/constant'

const emptyChartData = {
    labels: [],
    datasets: []
}
const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false
}

const height = 360

const PeriodicChart = ({ type }) => {
    const { pushNotification } = useContext(NotificationContext)
    const [data, setData] = useState(emptyChartData)
    const [loading, setLoading] = useState(false)

    async function fetchDetail (type) {
        try {
            setLoading(true)

            const params = { type }
            const { data: responseData } = await Axios.get(GetChart(), { params })
            const labels = responseData.data.map((x) => x.period_label)
            const counts = responseData.data.map((x) => x.count)
            setData({
                labels,
                datasets: [
                    {
                        label: 'Jumlah',
                        data: counts,
                        backgroundColor: chartColors.blue
                    }
                ]
            })
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchDetail(type)
    }, [])

    if (loading) {
        return (
            <div style={{ height: `${height}px` }} className="mt-3 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    return (
        <div style={{ height: `${height}px` }}>
            <Bar data={data} options={options} />
        </div>
    )
}

const App = () => {
    const [key, setKey] = useState('tab-daily')
    return (
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="my-3" fill mountOnEnter>
            <Tab eventKey="tab-daily" title={<h4>Harian</h4>}>
                <PeriodicChart type="daily" />
            </Tab>
            <Tab eventKey="tab-monthly" title={<h4>Bulanan</h4>}>
                <PeriodicChart type="monthly" />
            </Tab>
        </Tabs>
    )
}

export default App
