import Axios from 'axios'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js'
import React, { useState, useEffect, useContext } from 'react'
import { Card, Spinner } from 'react-bootstrap'

import SlikChart from './SlikChart'
import StatementChart from './StatementChart'
import VerificationIncomeChart from './VerificationIncomeChart'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'
import { GetUsageReportSection } from '../../utils/api'

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend)

function App () {
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const sectionMappers = {
        statement: [{
            title: 'Rekening Koran',
            chart: <StatementChart title="Rekening Koran" />
        }],
        slik: [{
            title: 'Sistem Layanan Informasi Keuangan (SLIK)',
            chart: <SlikChart title="Sistem Layanan Informasi Keuangan(SLIK)" />
        }],
        verification: [{
            title: 'Verifikasi Pendapatan Pemohon',
            chart: <VerificationIncomeChart title="Verifikasi Pendapatan Pemohon" />
        }]
    }

    async function fetchDetail (type) {
        try {
            setLoading(true)

            const params = { type }
            const { data: responseData } = await Axios.get(GetUsageReportSection(), { params })
            setData(responseData.data)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    // Inits.
    useEffect(() => {
        pushEvent('Usage Report Dashboard')
        fetchDetail()
    }, [pushEvent])

    return (
        <>
            <h2>Laporan Penggunaan</h2>
            {
                loading
                    ? (
                        <div className="mt-3 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )
                    : <>{
                        data
                            ?.map((item, itemIndex) => {
                                const sections = sectionMappers[item]
                                if (!sections) return <></>
                                return sections?.map((section, sectionIndex) => {
                                    return (
                                        <Card key={`${itemIndex}_${sectionIndex}`} className="mt-5">
                                            <Card.Header><h3>{section.title}</h3></Card.Header>
                                            <Card.Body>{section.chart}</Card.Body>
                                        </Card>
                                    )
                                })
                            })
                            ?.flat()
                    }</>
            }
        </>
    )
}

export default App
